<template>
  <div class="pa-2 pa-sm-5 grey lighten-3 tco-choose-filter">
    <v-row>
      <v-col cols="12" md="3">
        <span class="d-block">CERCA PER</span>
        <div class="d-flex flex-row">
          <v-combobox
            class="search-input"
            clearable
            filled
            solo
            dense
            height="48"
            v-model="searchWord"
            ref="autocomplete"
            :items="getItems"
            :search-input.sync="search"
            no-filter
            :label="$t('tco.perks.blocks.tcoProduct.filters.searchPlaceholder')"
            :placeholder="
              $t('tco.perks.blocks.tcoProduct.filters.searchPlaceholder')
            "
            flat
            item-text="name"
            item-value="name"
            hide-details
            hide-no-data
            return-object
            type="search"
            @keydown.enter.native.prevent.stop="doSearch"
            :menu-props="{
              closeOnContentClick: true,
              contentClass: 'searches-menu'
            }"
          >
            <!-- :menu-props="menuProps" -->
            <!-- :auto-select-first="true" -->
            <!-- cache-items -->
            <!-- :item-text="itemLabel" -->
            <template v-slot:item="{ item }">
              <div
                class="d-flex w-100 primary--text"
                :class="item.type == 'FastSearch' ? 'fast-search-btn' : ''"
              >
                <v-list-item-icon
                  v-if="item.type == 'Categorie'"
                  class="category-img"
                >
                  <img
                    :src="getImage(item)"
                    onerror="this.src='/no-icon.png'"
                  />
                </v-list-item-icon>
                <v-list-item-icon v-else-if="item.type == 'Ricerche'">
                  <v-icon @click.prevent.stop="deleteWord(item)" small>
                    $close
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-icon>
                  <v-icon small>$search</v-icon>
                </v-list-item-icon>
                <v-list-item-content @click="doSearch(item)">
                  <v-list-item-title>
                    <span
                      class="font-weight-bold primary--text text-body-2"
                      v-if="item.type == 'FastSearch'"
                    >
                      {{ $t("fastSearch.searchInput.label") }}
                    </span>
                    <text-highlight v-else :queries="search ? search : '*'">
                      {{ item.name }}
                    </text-highlight>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.type == 'Categorie'">
                    Cerca <strong>{{ search }}</strong> in {{ item.name }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.type == 'Marche'">
                    Cerca <strong>{{ search }}</strong> con marca
                    {{ item.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="my-0">
                  <div class="badge" v-if="item.count">
                    {{ item.count }}
                  </div>
                  <v-icon
                    v-if="item.type == 'Ricerche'"
                    @click.prevent.stop="search = item.name"
                    small
                  >
                    $arrowTopLeft
                  </v-icon>
                </v-list-item-action>
              </div>
            </template>
            <template slot="append">
              <v-icon @click.prevent.stop="doSearch" color="primary"
                >$search</v-icon
              >
            </template>
          </v-combobox>
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <span class="d-block">FILTRA PER</span>
        <v-select
          outlined
          v-model="categorySelected"
          hide-details
          return-object
          :items="categories"
          item-text="name"
          item-value="categoryId"
          @change="categoryChanged(categorySelected)"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="d-flex align-end">
        <v-select
          outlined
          :key="categorySelected && categorySelected.categoryId"
          v-model="categoryChildrenSelected"
          hide-details
          return-object
          :items="
            categorySelected && categorySelected.categories
              ? categorySelected.categories
              : []
          "
          item-text="name"
          item-value="categoryId"
          @change="categoryChanged(categoryChildrenSelected)"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="3">
        <span class="d-block">ORDINA PER</span>
        <v-select
          color="primary"
          :items="sortOptions"
          v-model="sortFilter"
          @change="sortFilterChanged(sortFilter)"
          :label="$t('products.orderBy')"
          outlined
          hide-details
        >
          <template v-slot:append
            ><v-icon color="primary" class="d-none d-sm-flex"
              >$expand</v-icon
            ></template
          >
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.tco-choose-filter {
  .tco-choose-product-rules {
    background-image: url("/img_layout/tco/tco-bg.png");
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid #c4942c;
  }
}
</style>
<script>
import TextHighlight from "vue-text-highlight";
import { mapState } from "vuex";

export default {
  name: "TcoProductsFilter",
  components: [TextHighlight],
  data() {
    return {
      searchWord: null,
      categorySelected: null,
      categoryChildrenSelected: null,
      modalOpen: false,
      search: null,
      sortFilter: null,
      sortOptions: [
        { value: "", text: "Rilevanza", icon: "ion-arrow-up-c" },
        { value: "alf_asc", text: "Descrizione", icon: "ion-arrow-up-c" },
        { value: "pri_asc", text: "Prezzo crescente", icon: "ion-arrow-up-c" },
        {
          value: "pri_des",
          text: "Prezzo decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "promo",
          text: "In promozione",
          icon: "ion-arrow-up-c"
        }
      ]
    };
  },
  props: {},
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    getItems() {
      let result = this.search ? this.items : [];
      return result;
    }
  },
  methods: {
    getImage(item) {
      return "/search-category/" + item.id + ".png";
    },
    categoryChanged(cat) {
      console.log("categoryChanged", cat);
      this.$emit("categoryChanged", cat);
    },
    sortFilterChanged(filter) {
      console.log("sortFilterChanged", filter);
      this.$emit("sortFilterChanged", filter);
    },
    doSearch(item) {
      this.items = [];

      var currentQuery = {};
      if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (this.search != null) {
        currentQuery.q = this.search;
      }

      this.$emit("wordChanged", this.search);
    }
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.fetchSuggestion();
      }
    }
  }
};
</script>
