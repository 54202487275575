<template>
  <div class="pa-2 pa-sm-5 tco-product-list-grid">
    <TcoProductsFilter
      class="mt-5"
      @sortFilterChanged="sortFilterChanged"
      @categoryChanged="categoryChanged"
      @wordChanged="wordChanged"
    />
    <v-row class="mt-5">
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        v-for="product in productList"
        :key="product.productId"
        class="product-col"
      >
        <ProductTcoCard
          v-bind:product="product"
          @openProductModal="openProductModal"
        />
      </v-col>
    </v-row>

    <v-btn
      block
      v-intersect="onIntersect"
      :loading="loading"
      @click="loadMore"
      v-if="$vuetify.breakpoint.xs && hasMoreItems"
    >
      <template v-if="hasMoreItems">{{ $t("products.showMore") }}</template>
      <template v-else>{{ $t("products.showAll") }}</template>
    </v-btn>
    <v-pagination
      v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
      :value="page"
      :page="pager.selPage"
      :length="pager.totPages ? pager.totPages : 0"
      :totalVisible="7"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
      class="py-6"
    ></v-pagination>
  </div>
</template>
<script>
import ProductTcoCard from "@/components/cardOro/ProductTcoCard.vue";
import TcoProductsFilter from "@/components/cardOro/TcoProductsFilter.vue";
import TcoProductsModalConfirm from "@/components/cardOro/TcoProductsModalConfirm.vue";

import ProductService from "~/service/productService";
import TigrosCardOroService from "@/service/tigrosCardOroService";
import AnalyticsService from "~/service/analyticsService";

export default {
  name: "TcoProductListGrid",
  components: {
    ProductTcoCard,
    TcoProductsFilter
  },
  data() {
    return {
      modalOpen: false,
      sortFilter: undefined,
      categoryFilter: undefined,
      wordSearched: undefined,
      productList: [],
      pager: {},
      page: 1,
      busy: false,
      loading: false,
      key: 0
    };
  },
  // props: {
  //   productList: { type: Array, default: () => [] }
  // },
  computed: {
    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    },
    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    }
  },
  methods: {
    async openProductModal(productSelected) {
      let res = await this.$dialog.show(TcoProductsModalConfirm, {
        waitForResult: true,
        width: 700,
        productSelected: productSelected
      });

      if (res) {
        let prodSelected = await TigrosCardOroService.setProduct(
          productSelected.productId
        );
        if (prodSelected) {
          this.$router.push({ name: "TcoPerks" });
        }
      }
    },
    async fetchTcoProductList(isAddMore = false) {
      let _this = this;
      this.loading = true;
      let res = await ProductService.search({
        // parent_category_id: this.categoryFilter,
        parent_category_id: this.categoryFilter
          ? this.categoryFilter.categoryId
          : global.config.perksCategoryId,
        q: this.wordSearched,
        page: this.page,
        page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
        sort: this.sortFilter,
        parent_catalog_restriction_id:
          global.config.perksCatalogRestrictionId || 1,
        warehouse_id: global.config.perksWarehouseId || 42
      });
      if (res && res.products) {
        if (isAddMore) {
          let prodList = this.productList.concat(res.products);
          _this.productList = prodList;
          console.log("this.productList", this.productList);
        } else {
          this.productList = res.products;
        }
        // this.productList = res.products;
        this.pager = res.page;
      } else {
        this.productList = [];
        AnalyticsService.search_null(this.wordSearched);
      }
      this.key++;
      this.loading = false;
    },
    sortFilterChanged(newSortFilter) {
      this.sortFilter = newSortFilter;
      this.fetchTcoProductList();
    },
    categoryChanged(newCategoryFilter) {
      this.categoryFilter = newCategoryFilter;
      this.fetchTcoProductList();
    },
    wordChanged(wordSearched) {
      this.wordSearched = wordSearched;
      this.fetchTcoProductList();
    },
    handlePageFilter(newPage) {
      var _this = this;
      this.page = newPage;
      console.log("page -> ", this.page);
      this.fetchTcoProductList();
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.page++;
        this.fetchTcoProductList(true);
      }
    }
  },
  mounted() {
    this.fetchTcoProductList();
  }
};
</script>
