<template>
  <v-card
    outlined
    rounded="md"
    class="product-tco-card-container product-card h-100"
    :to="{ name: 'Product', params: { slug: product.slug } }"
    ref="productCard"
  >
    <div class="product">
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            :src="product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img align-self-center"
            :width="$vuetify.breakpoint.xs ? 100 : 170"
            :height="$vuetify.breakpoint.xs ? 100 : 170"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />

          <div
            class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
          >
            <span class="name font-weight-bold">
              {{ product.name }}
            </span>
            <span class="short_descr">
              {{ product.shortDescr }}
            </span>
            <span class="descr">
              {{ product.description }}
            </span>
            <span
              class="weight-unit"
              v-if="
                product.productInfos &&
                  product.productInfos.TIPOLOGIA == 'Pezzo' &&
                  product.priceUmDisplay
              "
            >
              {{ $n(product.priceUmDisplay, "currency") }}/{{
                product.weightUnitDisplay
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <v-card-actions class="flex-column align-start w-100">
      <template v-if="product.available > 0 && product.priceDisplay">
        <div class="actions mb-1 mt-1">
          <!--  -->
          <ProductPrice v-if="product.priceDisplay" :product="product" />
        </div>
        <v-btn
          color="primary"
          depressed
          block
          @click.stop.prevent="selectProduct"
          v-if="showBtn"
        >
          <v-icon color="white" small class="mr-2">$heartfull</v-icon>
          {{ $t("tco.perks.blocks.tcoProduct.products.chooseProductBtn") }}
        </v-btn>
      </template>
      <template v-else>
        <div class="primary--text text-center w-100 mt-5 mt-sm-16">
          {{ $t("tco.perks.blocks.tcoProduct.products.notAvailable") }}
        </div>
      </template>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.product-tco-card-container {
  .product-img {
    min-width: 100px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 17px;
    }
  }
  .product .description .name {
    font-family: $heading-font-family;
  }
  .actions {
    min-height: 58px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-price {
      width: 140px;
      text-align: center;
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";

export default {
  name: "ProductTcoCard",
  props: {
    product: { type: Object, required: true },
    showBtn: { type: Boolean, default: true }
  },
  components: {
    ProductPrice
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    selectProduct() {
      this.$emit("openProductModal", this.product);
    }
  }
};
</script>
