<template>
  <div class="pa-2 pa-sm-5 primary tco-choose-product-rules">
    <h2
      class="d-flex flex-column flex-sm-row align-center align-sm-end justify-center"
    >
      <img
        height="130"
        width="232"
        alt="immagine sconto percentuale"
        src="/img_layout/tco/tco-discount.png"
      />

      <span class="pb-4">sul tuo prodotto preferito</span>
    </h2>
    <v-expansion-panels
      flat
      outlined
      class="tco-block-card primary--text h-100 text-center d-flex flex-column align-center justify-space-between"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t("tco.perks.blocks.tcoProduct.rules.title") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- wp non scende block corretto? -->
          <div
            v-if="tcoProductRules"
            v-html="tcoProductRules.content.rendered"
          ></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<style lang="scss">
.tco-choose-product-rules {
  background-image: url("/img_layout/tco/tco-bg.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid $gold;
}
</style>
<script>
import cmService from "~/service/cmService";

import get from "lodash/get";

export default {
  name: "TcoChooseProductRules",
  data() {
    return {
      modalOpen: false,
      tcoProductRules: null
    };
  },
  computed: {},
  methods: {
    async loadTcoProductRulesBlock() {
      let res = await cmService.getUxBlocks("tco-gestione-sconti");
      // wp non scende block corretto?
      this.tcoProductRules = get(res, "[4]", []);
    }
  },
  mounted() {
    this.loadTcoProductRulesBlock();
  }
};
</script>
