<template>
  <div class="tco-choose-product-container">
    <TcoChooseProductRules />

    <TcoProductListGrid class="mt-5" />
  </div>
</template>
<script>
import TcoChooseProductRules from "@/components/cardOro/TcoChooseProductRules.vue";
import TcoProductListGrid from "@/components/cardOro/TcoProductListGrid.vue";

export default {
  name: "TcoChooseProduct",
  components: {
    TcoChooseProductRules,
    TcoProductListGrid
  },
  data() {
    return {
      modalOpen: false
    };
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
